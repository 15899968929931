// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-codes-cestau-apesteguia-tsx": () => import("./../../../src/pages/codes-cestau-apesteguia.tsx" /* webpackChunkName: "component---src-pages-codes-cestau-apesteguia-tsx" */),
  "component---src-pages-contacto-ok-tsx": () => import("./../../../src/pages/contacto-ok.tsx" /* webpackChunkName: "component---src-pages-contacto-ok-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-medidas-seguridad-covid-19-tsx": () => import("./../../../src/pages/medidas-seguridad-covid-19.tsx" /* webpackChunkName: "component---src-pages-medidas-seguridad-covid-19-tsx" */),
  "component---src-pages-que-tratamos-tsx": () => import("./../../../src/pages/que-tratamos.tsx" /* webpackChunkName: "component---src-pages-que-tratamos-tsx" */),
  "component---src-pages-tarjeta-regalo-canceled-tsx": () => import("./../../../src/pages/tarjeta-regalo/canceled.tsx" /* webpackChunkName: "component---src-pages-tarjeta-regalo-canceled-tsx" */),
  "component---src-pages-tarjeta-regalo-success-tsx": () => import("./../../../src/pages/tarjeta-regalo/success.tsx" /* webpackChunkName: "component---src-pages-tarjeta-regalo-success-tsx" */),
  "component---src-templates-grupo-de-tecnicas-tsx": () => import("./../../../src/templates/grupoDeTecnicas.tsx" /* webpackChunkName: "component---src-templates-grupo-de-tecnicas-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tecnica-tsx": () => import("./../../../src/templates/tecnica.tsx" /* webpackChunkName: "component---src-templates-tecnica-tsx" */)
}

