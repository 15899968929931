import React from 'react'
import { BasePage } from './src/containers/BasePage'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const wrapPageElement = ({ element, props }) => (
  <BasePage>{element}</BasePage>
)
